import React from "react";
import { FaLinkedinIn } from "react-icons/fa";

let data = [
  {
    images: require("../../assets/team/rizwan.jpg"),
    title: "Rizwan Ahmad",
    designation: "Founder",
    socialNetwork: [
      {
        icon: <FaLinkedinIn />,
        url: "https://www.linkedin.com/in/rizwan-ahmed-7242631b6/",
      },
    ],
  },
  {
    images: require("../../assets/team/mani.jpg"),
    title: "Abdul Manan",
    designation: "Software Engineer",
    socialNetwork: [
      {
        icon: <FaLinkedinIn />,
        url: "https://www.linkedin.com/in/abdul-manan-qureshi-72886812a/",
      },
    ],
  },
  {
    images: require("../../assets/team/bukhari.jpg"),
    title: "Ali Zain",
    designation: "Full Stack Engineer",
    socialNetwork: [
      {
        icon: <FaLinkedinIn />,
        url: "https://www.linkedin.com/in/ali-zain-bukhari-071368154/",
      },
    ],
  },
  {
    images: require("../../assets/team/ammar.png"),
    title: "Ammar Ali",
    designation: "React Developer",
    socialNetwork: [
      {
        icon: <FaLinkedinIn />,
        url: "https://www.linkedin.com/in/ammar-ali-59832a1b7/",
      },
    ],
  },
  {
    images: require("../../assets/team/rana.jpg"),
    title: "Mohsin Rana",
    designation: "Full Stack Engineer",
    socialNetwork: [
      {
        icon: <FaLinkedinIn />,
        url: "https://www.linkedin.com/in/mohisn-rana-30b903187/",
      },
    ],
  },
  {
    images: require("../../assets/team/haris.jpg"),
    title: "Harris Memoon",
    designation: "Front-end Developer",
    socialNetwork: [
      {
        icon: <FaLinkedinIn />,
        url: "https://www.linkedin.com/in/haris-memoon-4aa179194/",
      },
    ],
  },
  {
    images: require("../../assets/team/wajid.jpg"),
    title: "Wajid Javaid",
    designation: "Full Stack Developer",
    socialNetwork: [
      {
        icon: <FaLinkedinIn />,
        url: "https://pk.linkedin.com/public-profile/in/wajid-javed-4b86a2134",
      },
    ],
  },
];

export default data;
